import React, { Component } from 'react';

import Layout from '../components/layout';
import SEO from "../components/seo";

import './careers.scss';

import SimpleFormModal from '../components/utilities/SimpleFormModal';

class CareersForm extends Component {

    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
        this.openModal = this.openModal.bind(this);
    };
    openModal() {
        this.modalRef.current.handleOpen();
    };

	render() {

		return (
            <React.Fragment>
                <div className="btn btn-black" onClick={() => this.openModal()}>Submit Resume</div>
                <SimpleFormModal innerRef={this.modalRef} />
            </React.Fragment>
        );
	}
}

export default ({pageContext}) => (
    <Layout>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image}
            link={pageContext.link}
            />        
        <div className={`${pageContext.slug} default-template`}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="content-block" dangerouslySetInnerHTML={{__html: pageContext.content}}></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <CareersForm />
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)